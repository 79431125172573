// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

import Stack from "@material-ui/core/Stack";
import Typography from "@material-ui/core/Typography";
import { useState, useEffect } from "react";
import SecureLS from "secure-ls";
import Page from "src/components/Page";
import LoadingButton from "@material-ui/lab/LoadingButton";
import FirebaseApp from "firebase/app";
import { useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import "firebase/auth";
import { getSessionToken } from "../../utils/sessionManager";

import { useNavigate } from "react-router-dom";

import "firebase/auth";

var ls = new SecureLS();

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const config = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
  },
};


// ----------------------------------------------------------------------

export default function Login() {


  const theme = useTheme();

  var [userCurrentState, setUserCurrentState] = useState("Email");
  var [isSubmitting, setSubmitting] = useState(false);
  var [EmailNumber, setEmailNumber] = useState(null);
  const [params, setSearchParams] = useSearchParams();
  var [otpNumber, setOtpNumber] = useState(null);
  var [askName, setAskName] = useState();
  var [name, setName] = useState("");
  const location = useLocation();
  var [customToken, setCustomToken] = useState(null);

  var [isVerified, setIsVerified] = useState(
    localStorage.getItem("userVerified")
      ? localStorage.getItem("userVerified")
      : false
  );

  var [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("userAuthenticated")
      ? localStorage.getItem("userAuthenticated")
      : false
  );
  const navigate = useNavigate();

  const ContentStyle = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: 500,
    backgroundColor: `${theme.palette.background.card}`,
    borderRadius: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 2, 2),
    padding: theme.spacing(5, 5),
  }));

  // console.log("al");
  const SectionStyle = styled("div")(({ theme }) => ({
    maxWidth: 600,
    margin: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(5, 5),
  }));

  if (
    isAuthenticated !== null &&
    isAuthenticated.toString() == "true" &&
    isVerified !== null &&
    isVerified.toString() == "true"
  ) {
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => { })()
    //   : console.log("AuthGuard : going to main screen");
    navigate("/dashboard/app");
  }

  const uid = params.get("uid");

  useEffect(() => {
    if (uid) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/user/getCustomToken`,
          { uid },
          config
        )
        .then((res) => {
          const token = res.data.customToken;
          signInWithCustomToken(token);
        });
    }
  }, [uid]);

  const submitEmailToMongo = async (event, EmailVal) => {
    setSubmitting((isSubmitting) => true);

    axios
      .post(
        `${process.env.REACT_APP_TATA_POWER_BACKEND_URL}/auth/getUserByEmail`,
        { email: EmailVal },
        config
      )
      .then((res) => {
        if (res.data.success) {
          const response = res.data;
          if (response?.user?.emailVerified && response?.user?.password) {
            setUserCurrentState("Password")
            setSubmitting((isSubmitting) => false);

          } else if(response?.user?.emailVerified){
            setUserCurrentState("SetPassword")
            setSubmitting((isSubmitting) => false);

          }
           else {
            setUserCurrentState("VerifyEmail")
            setSubmitting((isSubmitting) => false);

          }
        } else {
          setUserCurrentState("Email");
          setSubmitting((isSubmitting) => false);
          Swal.fire("Error", res.data.message, "error")
        }
      })
      .catch((err) => {
        setUserCurrentState("Email");
      });
  };

  const submitEmail = async (event, EmailVal) => {

    setSubmitting((isSubmitting) => true);
    setUserCurrentState((isSubmitting) => "Email");

    if (event != null && event != undefined) event.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          deviceType: "sheruApp",
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
          userType: "swapper",
          "Access-Control-Allow-Origin": "*",
          latitude: "empty",
          longitude: "empty",
          zoneCode: "empty",
          zoneLat: "empty",
          zoneLong: "empty",
        },
        body: JSON.stringify({
          EmailNumber: EmailVal,
        }),
      };
      const url =
        process.env.REACT_APP_ENVIRONMENT == "production"
          ? "https://tezz.sheru.se/" + "swapper/loginOtp"
          : "https://tezztest.sheru.se/" + "swapper/loginOtp";

      fetch(url, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          setSubmitting((isSubmitting) => false);
          setUserCurrentState((isSubmitting) => "otp");
        });
    } catch (error) {
      alert(error?.message);
      setSubmitting((isSubmitting) => false);
      setUserCurrentState("Email");
    }
  };

  const submitOtpMongo = async (event, otpVal, nameVal) => {

    setSubmitting((isSubmitting) => true);
    setUserCurrentState((isSubmitting) => "otp");

    if (event != null && event != undefined) event.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          deviceType: "sheruApp",
          userType: "swapper",
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
          "Access-Control-Allow-Origin": "*",
          latitude: "empty",
          longitude: "empty",
          zoneCode: "empty",
          zoneLat: "empty",
          zoneLong: "empty",
        },
        body: JSON.stringify({
          EmailNumber: EmailNumber,
          otp: otpVal,
        }),
      };
      const url =
        process.env.REACT_APP_ENVIRONMENT == "production"
          ? "https://tezz.sheru.se/" + "swapper/verifyLoginOtp"
          : "https://tezztest.sheru.se/" + "swapper/verifyLoginOtp";

      fetch(url, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          var requestData = responseData.requestData;
          if (requestData != null) {
            var status = requestData.status;
            if (status != null && status === "success") {
              var customTokenVal = requestData.customToken;
              if (customTokenVal != null && !(customTokenVal == "")) {

                const resData = {
                  firstName: nameVal,
                  EmailNumber,
                };
                axios
                  .post(
                    `${process.env.REACT_APP_BACKEND_URL}/user/updateSwapperName`,
                    resData,
                    config
                  )
                  .then((res) => {
                    if (res.data) {
                      const response = res.data;
                      if (response.success) {
                        setCustomToken((customToken) => customTokenVal);
                        signInWithCustomToken(customTokenVal);
                        return;
                      } else {
                        alert("some error occured");
                      }
                    }
                  });
                // setCustomToken(customToken => customTokenVal);
                // signInWithCustomToken(customTokenVal);
                // return;
              } else {
                alert(
                  requestData.message != null
                    ? requestData.message
                    : "Some error occured, please try again!"
                );
                setSubmitting((isSubmitting) => false);
                setUserCurrentState("Email");
              }
            } else {
              alert(
                requestData.message != null
                  ? requestData.message
                  : "Some error occured, please try again!"
              );
              setSubmitting((isSubmitting) => false);
              setUserCurrentState("Email");
            }
          } else {
            alert(responseData.message);
            setSubmitting((isSubmitting) => false);
            setUserCurrentState("Email");
          }
        });
    } catch (error) {

      // console.error(error);
      alert(error?.message);

      setSubmitting((isSubmitting) => false);
      setUserCurrentState("Email");
    }
  };

  const submitVerifyOTP = async (event, otpVal) => {

    if (event != null && event != undefined) event.preventDefault();

    setSubmitting((isSubmitting) => true);

    axios
      .post(
        `${process.env.REACT_APP_TATA_POWER_BACKEND_URL}/auth/emailVerification`,
        { email: EmailNumber,
          otp:otpVal
        },
        config
      )
      .then((res) => {
        if (res.data.success) {
          setUserCurrentState("SetPassword")
          setSubmitting((isSubmitting) => false);

        } else {
          Swal.fire("Error", res.data.message, "error")
          setSubmitting((isSubmitting) => false);

        }
      })
      .catch((err) => {
        setUserCurrentState("Email");
        setSubmitting((isSubmitting) => false);
      });


   
  };

  const submitSetPassword = async(event,password,confirmPassword) => {
   setSubmitting(true)
    if (password !== confirmPassword) {
      setSubmitting(false)
      return Swal.fire("Error", "Password and Confirm Password does not match", "error")
    }
      
    axios
      .post(
        `${process.env.REACT_APP_TATA_POWER_BACKEND_URL}/auth/setUserPassword`,
        { email: EmailNumber,
          password,
          confirmPassword
        },
        config
      )
      .then((res) => {
        if (res.data.success) {
          console.log("Login successful")
      signInWithCustomToken(res.data.customToken)

        } else {
          Swal.fire("Error", res.data.message, "error")
          setSubmitting(false)

        }
      })
      .catch((err) => {
        setUserCurrentState("SetPassword");
        setSubmitting(false)

      });


}
  const submitPassword = async(event,password) => {
   setSubmitting(true)
  
      
    axios
      .post(
        `${process.env.REACT_APP_TATA_POWER_BACKEND_URL}/auth/loginWithEmail`,
        { email: EmailNumber,
          password,
          
        },
        config
      )
      .then((res) => {
        if (res.data.success) {
          console.log("Login successful")
      signInWithCustomToken(res.data.customToken)

        } else {
          Swal.fire("Error", res.data.message, "error")
          setSubmitting(false)

        }
      })
      .catch((err) => {
        setUserCurrentState("Password");
        setSubmitting(false)
      });


}



   

  async function signInWithCustomToken(customToken){

    var customTokenVal = customToken;


    try {
      // console.log("Firebase app")
      await FirebaseApp.auth()
        .setPersistence(FirebaseApp.auth.Auth.Persistence.LOCAL)
        .then(
          await FirebaseApp.auth()
            .signInWithCustomToken(customToken)
            .then((res) => {

              localStorage.setItem("userAuthenticated", true);
              localStorage.setItem("userId", res.user.uid);
                getSessionToken();
                localStorage.setItem("userVerified", true);
                // console.log("Verified");
                // console.log(location.pathname, "location");
    
                window.location.pathname = "/dashboard/app"
              
            })
        )
        .catch((error) => {

          alert(error.message);
          setSubmitting((isSubmitting) => false);
          setUserCurrentState("Email");
        });
    } catch (error) {

      alert(error.message);
      // console.log(error)

      setSubmitting((isSubmitting) => false);
      setUserCurrentState("Email");
    }
  };

  const LeftView = () => {
    return (
      <Grid item xs={12} sm={12} md={6} ls={6} xl={6}
        sx={{
          display: { xs: 'none', sm: 'none', md: 'flex', ls: 'flex', sx: 'flex' }, height: { xs: '0vh', sm: '0vh', md: '100vh', ls: '100vh', sx: '100vh' },
          flexDirection: "column", alignItems: "center", justifyContent: "center", background: "#009aef"
        }}>
        <SectionStyle>
          <Typography variant="h2" sx={{ px: 5, color: "white" }}>
            Hi, Welcome!
          </Typography>
          <img
            src="/img_new/log_in_v3.svg"
            style={{ width: "100%", marginTop: "30px" }}
            alt="login"
          />
          <p style={{ marginTop: '30px', color: "white", textAlign: "center", fontSize: "120%" }}>
            www.ems.scalebat.com
          </p>

        </SectionStyle>

      </Grid>
    )
  }

  const OtpSubmitClicked = (event, OtpVal) => {
    setOtpNumber(OtpVal);
    submitVerifyOTP(OtpVal)
  }

  const OtpAndNameSubmitClicked = (event, otpVal, nameVal) => {
    setOtpNumber(otpVal);
    setName(nameVal);
    submitOtpMongo(event, otpVal, nameVal)
  }

  const OtpView = () => {
    var [otpLocal, setOtpLocal] = useState(null);
    var [nameLocal, setNameLocal] = useState(name);

    return (
      <div>


        <Stack spacing={1}>

          <div style={{ marginTop: "10px" }}>
            <Typography sx={{ fontSize: "15px", marginBottom: '5px', color: `${theme.palette.text.secondary}`, fontSize: "15px" }}>
              Enter password
            </Typography>

            <TextField
              fullWidth
              autoComplete="password"
              size="small"
              type="text"
              value={otpLocal}
              disabled={userCurrentState != "otp"}
              style={{ fontSize: "22.5px" }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  fontSize: '22.5px', fontWeight: "bold", color: `${theme.palette.text.primary}`
                },
              }}
              onChange={(event) => {
                setOtpLocal((otpLocal) => event.target.value);
              }}
            />
          </div>

          {!isSubmitting && askName && (
            <div style={{ marginTop: "15px" }}>
              <Typography sx={{ fontSize: "15px", marginBottom: '5px', color: `${theme.palette.text.secondary}`, fontSize: "15px" }}>
                Enter name
              </Typography>
              <TextField
                style={{ fontSize: "22.5px" }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    fontSize: '22.5px', fontWeight: "bold", color: `${theme.palette.text.primary}`
                  },
                }}
                size="small"
                fullWidth
                autoComplete="name"
                value={nameLocal}
                onChange={(event) => {
                  setNameLocal(event.target.value);
                }}
              />
            </div>
          )}

          {!isSubmitting &&
            askName &&
            nameLocal !== null &&
            nameLocal !== "" ? (
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ marginTop: "30px", borderRadius: '10px' }}
              onClick={(e) => OtpAndNameSubmitClicked(e, otpLocal, nameLocal)}
            >
              Submit name and password
            </Button>
          ) : (
            <></>
          )}

          {!isSubmitting && !askName && (
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ marginTop: "30px", borderRadius: '10px' }}
              onClick={(e) => OtpSubmitClicked(e, otpLocal)}
            >
              Submit password
            </Button>
          )}

          {isSubmitting &&
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={true}
              style={{ marginTop: "20px" }}
            >
              Loading please wait
            </LoadingButton>
          }

        </Stack>

      </div>
    )
  }



  const PasswordView = () => {
    var [passwordLocal, setPasswordLocal] = useState(null);

    return (
      <div>


        <Stack spacing={1}>

          <div style={{ marginTop: "10px" }}>
            <Typography sx={{ fontSize: "15px", marginBottom: '5px', color: `${theme.palette.text.secondary}`, fontSize: "15px" }}>
              Enter your password
            </Typography>

            <TextField
              fullWidth
              autoComplete="password"
              size="small"
              type="password"
              value={passwordLocal}
              style={{ fontSize: "22.5px" }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  fontSize: '22.5px', fontWeight: "bold", color: `${theme.palette.text.primary}`
                },
              }}
              onChange={(event) => {
                setPasswordLocal((otpLocal) => event.target.value);
              }}
            />
          </div>

 

        </Stack>
        {isSubmitting ? (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={true}
            style={{ marginTop: "20px", borderRadius: "10px" }}
          >
            Loading please wait
          </LoadingButton>
        ) : (
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style={{ marginTop: "20px", borderRadius: "10px" }}
            onClick={(e) => submitPassword(e, passwordLocal)}
          >
            Submit
          </Button>
        )}
      </div>
    )
  }


  const submitEmailClicked = (event, EmailVal) => {
    setEmailNumber(EmailVal);
    submitEmailToMongo(event, EmailVal);
  }

  const submitOtpClicked = (event, OtpVal) => {
    setOtpNumber(OtpVal);
    submitVerifyOTP(event,OtpVal)
  }



  const EnterEmailView = () => {

    var [EmailNumberLocal, setEmailNumberLocal] = useState(null);

    return (
      <div>
        <Typography sx={{ mb: 1, color: `${theme.palette.text.secondary}`, fontSize: "20px" }}>
          Enter your email below.
        </Typography>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            size="small"
            type="text"
            value={EmailNumberLocal}
            disabled={isSubmitting}
            style={{
              borderRadius: "10px", fontSize: '22.5px',
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              textAlign: 'center', fontWeight: "bold", color: `${theme.palette.text.primary}`
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
                paddingLeft: "25px",
                paddingRight: "25px",
                textAlign: 'center',
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                fontSize: '22.5px', fontWeight: "bold", color: `${theme.palette.text.primary}`
              },
            }}
            onChange={(event) => {
              setEmailNumberLocal((EmailNumberLocal) => event.target.value);
            }}
          />
        </Stack>



        {isSubmitting ? (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={true}
            style={{ marginTop: "20px", borderRadius: "10px" }}
          >
            Loading please wait
          </LoadingButton>
        ) : (
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style={{ marginTop: "20px", borderRadius: "10px" }}
            onClick={(e) => submitEmailClicked(e, EmailNumberLocal)}
          >
            Submit
          </Button>
        )}
      </div>
    )
  }


  const SetPasswordView = () => {
    var [passwordLocal, setPasswordLocal] = useState(null);
    var [confirmPasswordLocal, setConfirmPasswordLocal] = useState(null);

    return (
      <div>
        <Typography sx={{ mb: 1, color: `${theme.palette.text.secondary}`, fontSize: "20px" }}>
          Email verified please set your password.
        </Typography>
        <Stack spacing={3}>
          <TextField
            fullWidth
            value={passwordLocal}
            label='Password'
            type='password'
            style={{ marginBottom: 4, marginTop: 1, minWidth: "300px" }} onChange={(e) => { setPasswordLocal(e.target.value) }}

          />
          <TextField
            fullWidth
            label='Confirm Password'
            value={confirmPasswordLocal}
            type='password'
            style={{ marginBottom: 4, marginTop: 1, minWidth: "300px" }} onChange={(e) => { setConfirmPasswordLocal(e.target.value) }}
          />


          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <p style={{ fontSize: "85%", color: `${theme.palette.text.disabled}`, marginLeft: '5px' }}>Password must be atleast 6 digits long</p>
          </div>
        </Stack>



        {isSubmitting ? (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={true}
            style={{ marginTop: "20px", borderRadius: "10px" }}
          >
            Loading please wait
          </LoadingButton>
        ) : (
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style={{ marginTop: "20px", borderRadius: "10px" }}
            onClick={(e) => submitSetPassword(e, passwordLocal,confirmPasswordLocal)}
          >
            Submit
          </Button>
        )}
      </div>
    )
  }
  const VerifyEmailView = () => {
    var [OTPLocal, setOTPLocal] = useState(null);

    return (
      <div>
        <Typography sx={{ mb: 1, color: `${theme.palette.text.secondary}`, fontSize: "20px" }}>
          An OTP has been sent to your email. Please verify the OTP
        </Typography>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="email"
            size="small"
            type="text"
            value={OTPLocal}
            disabled={isSubmitting}
            label="Enter OTP"
            style={{
              borderRadius: "10px", fontSize: '22.5px',
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
              textAlign: 'center', fontWeight: "bold", color: `${theme.palette.text.primary}`
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
                paddingLeft: "25px",
                paddingRight: "25px",
                textAlign: 'center',
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                fontSize: '22.5px', fontWeight: "bold", color: `${theme.palette.text.primary}`
              },
            }}
            onChange={(event) => {
              setOTPLocal((EmailNumberLocal) => event.target.value);
            }}
          />
        </Stack>



        {isSubmitting ? (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={true}
            style={{ marginTop: "20px", borderRadius: "10px" }}
          >
            Loading please wait
          </LoadingButton>
        ) : (
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style={{ marginTop: "20px", borderRadius: "10px" }}
            onClick={(e) => submitOtpClicked(e, OTPLocal)}
          >
            Submit
          </Button>
        )}
      </div>
    )
  }

  // const RolesView = () => {
  //   return (
  //     <div style={{ padding: "20px", gap: "10px" }}>

  //       <p>Verifying your profile, please wait</p>

  //       {isSubmitting &&
  //         <LoadingButton
  //           fullWidth
  //           size="large"
  //           type="submit"
  //           variant="contained"
  //           loading={true}
  //           style={{ marginTop: "20px" }}
  //         >
  //           Loading please wait
  //         </LoadingButton>
  //       }

  //       {!isSubmitting && (
  //         <Button
  //           fullWidth
  //           size="large"
  //           type="submit"
  //           variant="contained"
  //           style={{ marginTop: "20px" }}
  //           onClick={(e) => verifyRoles()}
  //         >
  //           Next
  //         </Button>
  //       )}
  //     </div>
  //   )
  // }


  const RightView = () => {
    return (
      <Grid item xs={12} sm={12} md={6} ls={6} xl={6} style={{ minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 4 }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "15px", alignItems: 'center' }}>
                <img src="/icon_new/sheru_black.svg" style={{ maxWidth: "200px", maxHeight: "30px" }} />
                <img src="/logo/scalebat/scalebat_logo_blue_black.png" style={{ maxWidth: "200px", maxHeight: "30px" }} />
                {/* <Typography variant="h4" gutterBottom style={{ marginTop:"10px", padding: "0px", color: `${theme.palette.text.primary}` }}>
                 ScaleBat
                </Typography> */}
              </div>
            </div>
          </Stack>

          {
            userCurrentState == "Password" ? (<PasswordView />) :
              (userCurrentState == "SetPassword" ? <SetPasswordView /> :
                (userCurrentState == "VerifyEmail" ? <VerifyEmailView /> :
                  <EnterEmailView />))
          }        </ContentStyle>
      </Grid>
    )
  }

  return (
    <Page style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <Grid container>
          <LeftView />
          <RightView />
        </Grid>
      </div>
    </Page>
  );
}
