import React, { useEffect, useState } from 'react';


import axios from 'axios';
import { useQuery } from '@apollo/client';
import FirebaseApp from 'firebase/app'
import 'firebase/database'

//mui components
import Badge from '@mui/material/Badge';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton'
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Button from "@material-ui/core/Button";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CircularProgress from '@mui/material/CircularProgress'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Icon } from '@iconify/react';
import ToggleButton from '@mui/material/ToggleButton';
import Popover from '@mui/material/Popover';

//icons
import HistoryIcon from '@mui/icons-material/History';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BatterySaverIcon from '@mui/icons-material/BatterySaver';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

//css
import styles from   "./notification_component.module.css";
import { GET_NOTIFICATIONS } from './query';

export default function NotificationComponent({user}) {
    const config = {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
        },
      };
    console.log(user, "17")

    const time = 6000
    const ticks = 6
    const countdown = 1000

    const getColor = (purpose, opacity) => {
        const color = {
            alert: `rgba(254, 63, 63,${opacity})`,
            info: `rgba(169, 204, 227,${opacity})`,
            warning: `rgba(192, 180, 10,${opacity})`,
        }
        return color[purpose]
    }


    const markNotificationAsRead = (notification) => {

        if (notification.markedAsRead) {
            return
        }

        const url = `${process.env.REACT_APP_TATA_POWER_BACKEND_URL}/notification/markANotificationRead`
        const reqBody = {
            notificationMongoId: notification._id,
        }
        axios.post(url, reqBody, config).then((res) => {

            if (res.data.success) {
                console.log("success")
            } else {
                console.error(res.data.message)
            }
        }).catch((err) => {
            console.error(err)
        })
    }

    const getIcon = (alertType) => {
        switch (alertType) {
            case "batteryAlert":
                return <BatteryAlertIcon />
            case "billingExpired":
                return <AttachMoneyIcon />

            case "onboardingCompleted":
                return <BatterySaverIcon />

            case "billingSuccess":
                return <AttachMoneyIcon />

            case "newFeature":
                return <NotificationsActiveIcon />

            case "onboardingFailed":
                return <BatterySaverIcon />
            case "billingExpiringSoon":
                return <AttachMoneyIcon />
            default:
                return <NotificationsActiveIcon />
        }
    }

    const getTimeDifference = (time, abs) => {

        const prevTime = new Date(time).getTime()
        const currentTime = new Date().getTime()
        const timeDifference = currentTime - prevTime
        const timeDifferenceInMinutes = timeDifference / 60000
        if (abs) {
            return timeDifferenceInMinutes
        } else if (timeDifferenceInMinutes <= 1) {
            if (timeDifference < 0) {
                return `0 seconds ago`

            } else {
                return `${(timeDifferenceInMinutes * 60).toFixed(0)} s`
            }
        } else if (timeDifferenceInMinutes > 1 && timeDifferenceInMinutes <= 60) {
            return `${timeDifferenceInMinutes.toFixed(0)} m`
        } else if (timeDifferenceInMinutes > 60 && timeDifferenceInMinutes <= 1440) {
            const timeDifferenceInHours = (timeDifferenceInMinutes / 60).toFixed(2)
            const hours = String(timeDifferenceInHours).split('.')[0]
            console.log(String(timeDifferenceInHours.split('.')))
            const minutes = (parseFloat('0.' + String(timeDifferenceInHours).split('.')[1]) * 60).toFixed(0)
            return `${hours} h  ${minutes} m`
        } else if (timeDifferenceInMinutes > 1440) {
            const timeDifferenceInDays = (timeDifferenceInMinutes / 1440).toFixed(2)
            const days = String(timeDifferenceInDays).split('.')[0]
            const hours = (parseFloat('0.' + String(timeDifferenceInDays).split('.')[1]) * 24).toFixed(0)
            return `${days} d  ${hours} h`
        }
    }


    const NotificationHistory = () => {

        const [firebaseData, setFirebaseData] = useState(0);
        const [count, setCount] = useState(0)
        const [notificationsOpen, setNotificationsOpen] = useState(false);
        const [displayType, setDisplayType] = useState("Pending");

        const updateDisplayType = (
            event: React.MouseEvent<HTMLElement>,
            newAlignment: string | null,
            ) => {
            if (newAlignment !== null) {
              setDisplayType(newAlignment);
            }
        };

        const NotificationsList = () => {
            
            const [notifications, setNotifications] = useState([]);

            const { loading, data, error } = useQuery(GET_NOTIFICATIONS, {
                variables: {
                    filter: {
                        markedAsRead: displayType == "Pending" ? false : true
                    },
                    page: 1,
                    perPage: 20,
                    sort: "CREATEDAT_DESC",

                },
                fetchPolicy: "network-only",
            }, displayType)

            useEffect(() => {
                if (data) {
                    console.log(data, "25")
                    setNotifications(data.EMSNotificationsPaginat.items)
                    setCount(data.EMSNotificationsPaginat.count)
                }
            }, [loading, error, displayType])

            const NotificationItemContainer = ({notification, index}) => {

                const [notificationPending, setNotificationPending] = useState(false);

                const markNotificationAsAcknowledged = () => {
                    setNotificationPending(true);
                    const url = `${process.env.REACT_APP_TATA_POWER_BACKEND_URL}/notification/acknowledgeANotification`
                    const reqBody = { notificationMongoId: notification?._id, uid:user?.uid}
                    axios.post(url, reqBody, config).then((res) => {
                        if (res.data.success) {
                            console.log("success")
                        } else {
                            console.error(res.data.message)
                        }
                        setNotificationPending(false);
                    }).catch((err) => {
                        console.error(err)
                    })
                }

                return(
                    <ListItemButton
                        className={styles.listItem}
                        // onClick={() => {
                        //     if (notification.redirectUrl) {
                        //         markNotificationAsRead(notification)
                        //         window.open(notification.redirectUrl)

                        //     }
                        // }}
                        style={{ width: '100%', backgroundColor: getColor(notification.notificationType, 0.1), padding: '10px', borderRadius: '15px', maxHeight: '100px', maxWidth:"400px"}}

                    >
                        <ListItemAvatar>
                            <Avatar style={{ backgroundColor: getColor(notification.notificationType, 0.6) }}>
                                {getIcon(notification.notificationSubType)}
                            </Avatar>
                        </ListItemAvatar>

                        <div style={{ width: '100%' }}>
                            <div style={{ fontSize: '.8rem', fontWeight: '400' }}>{notification.notificationTitle}</div>
                                <div style={{ fontSize: '1rem' }}>{notification.notificationBody}</div>
                            
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{fontSize:"80%", display:"flex", flexDirection:'row', alignItems:'center', gap:"5px"}}>
                                    <HistoryIcon fontSize='small' />   
                                    {getTimeDifference(notification.createdAt, false)}
                                </div>
                                {notificationPending ? <CircularProgress style={{width:"25px", height:'25px'}} /> : 
                                    <Button onClick={() => markNotificationAsAcknowledged()}>Mark as read</Button>
                                }
                            </div>
                        </div>
                    </ListItemButton>
                )
            }

            return (
                <Popover 
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                  sx={{ '& .MuiPopover-root': { width: '450px', minWidth:"450px" } }} open={notificationsOpen} onClose={() => { setNotificationsOpen(false) }} style={{ width: '450px', maxWidth:"450px", display:'flex', flexDirection:'column', gap:'0px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', position:'relative', gap: '5px', marginTop: '10px', minWidth:'450px', width:"450px", maxWidth:'450px'}} >
                        <div style={{ minWidth:'400px', width:'400px', maxWidth:'400px', fontSize: '1rem', padding: '5px', marginLeft:'10px', fontWeight: "bold", display:'flex', flexDirection:"row", alignItems:'center', justifyContent:'space-between' }}>
                            <Typography>Notifications</Typography>
                            <HighlightOffIcon className='clickable' onClick={() => { setNotificationsOpen(false) }}/>
                        </div>

                        <div style={{display:"flex", flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                            <ToggleButtonGroup
                                value={displayType}
                                exclusive
                                onChange={updateDisplayType}
                                size='medium'
                                style={{ }}
                                aria-label="Read-Unread"
                            >
                                <ToggleButton value="Pending" aria-label="Pending" size='small' style={{padding:"5px 10px" }}>
                                    {displayType == "Pending" ? 
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'5px', fontSize:'100%'}}><Icon icon="fluent:stack-24-filled" /> <p><b>Pending</b></p></div> : 
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'5px', fontSize:'100%'}}><Icon icon="fluent:stack-20-regular" /> <p>Pending</p></div>
                                    }
                                </ToggleButton>
                                <ToggleButton value="Acknowledged" aria-label="Acknowledged" size='small' style={{padding:"5px 10px" }}>
                                    {displayType == "Acknowledged" ? 
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'5px', fontSize:'100%'}}><Icon icon="fluent:table-28-filled" />  <p><b>Acknowledged</b></p> </div> : 
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'5px', fontSize:'100%'}}><Icon icon="fluent:table-32-regular" /> <p>Acknowledged</p></div>
                                    }
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>

                        {loading ?
                            <div style={{ overflowY: 'scroll', height: '350px', display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'center', width:'420px', maxWidth:'420px' }}>
                                <CircularProgress />
                            </div>
                            :
                            (
                                notifications != null && notifications?.length > 0 ?
                                    <div style={{ overflowY: 'scroll', height: '50vh', display: 'flex', flexDirection: 'column', gap: '.4rem', marginTop: '.1rem', padding: '10px', width:'420px', maxWidth:'420px' }}>

                                        {notifications?.map((notification, index) => {
                                            return ( <NotificationItemContainer notification={notification} index={index}/>)
                                        })
                                        }
                                    </div>
                                :
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: "30px",
                                            padding: "10px",
                                            minHeight:"300px",
                                            gap: "20px"
                                        }}
                                    >
                                        <img src="/icon_new/empty_v2.svg" style={{ width: '150px', minWidth: "150px", height: '150px', minHeight: "150px" }} />

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            <p style={{ fontSize: "110%" }}>All messages have been read!</p>

                                        </div>

                                    </div>
                            )
                        }
                    </div> 
                </Popover>
            )
        }

        useEffect(() => {
            if (user?.uid) {
                const firebaseRef = FirebaseApp.database().ref(`/users/${user.uid}/notifications/notificationCount`);
                console.log("32",)

                let oldCount = null
                firebaseRef.on("value", async (snapshot) => {

                    if (snapshot.val()) {
                        console.log(snapshot.val(), "76")
                        setFirebaseData(snapshot.val())
                    }
                });

                return (() => {
                    firebaseRef.off()
                })
            }
        }, [])

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                <Link className={styles.coreSingleCardLink} to={`/dashboard/notifications`} target='_blank' style={{textDecoration:"none"}}>
                    <div 
                    // onClick={() => { setNotificationsOpen(!notificationsOpen) }}
                        style={{ maxWidth: '75px', padding: "5px", display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center' }} className='clickable'>
                        <Badge badgeContent={user ? firebaseData : 0} color="primary" >
                            <NotificationsActiveIcon color='primary' />
                        </Badge>
                        <p style={{ color: "gray", fontSize: '70%' }}>Notification</p>
                    </div>
                </Link>

                {notificationsOpen &&
                    <NotificationsList />
                }
            </div>
        )
    }

    

    const AlertRow = ({ val, kkey, count }) => {

        console.log(val, kkey)
        const [timeLeft, setTimeLeft] = useState(6)
        console.log("initializing alert row")

        const clearFIrebaseNotification = async (kkey) => {
            console.log("clearFIrebaseNotification")
            const userId = user.uid

            if (userId != null && userId != "" && userId.length > 5 && kkey != null && kkey != "" && kkey.length > 2) {
                const key = `/users/${user.uid}/notifications/array/${kkey}`
                const url = `${process.env.REACT_APP_TATA_POWER_BACKEND_URL}/notification/firebaseKeyAction`;
                const res = await axios.post(url, { key, action: "remove" }, config)
                console.log(res)

            }

            console.log("clearFIrebaseNotification", userId, kkey)
        }


        useEffect(() => {
            setTimeout(async () => {
                await clearFIrebaseNotification(kkey)
            }, 6000)
        }, [kkey])

        useEffect(() => {
            var ticksMade = 0
            console.log("intervaleffect", ticksMade, timeLeft)
            const intId = setInterval(() => {
                if (ticksMade == 6) {
                    clearInterval(intId)
                    setTimeLeft(0)
                } else {
                    let TL = timeLeft - 1
                    setTimeLeft(TL)
                    ticksMade++
                }
            }, 6000)
            return () => clearInterval(intId)
        }, [])

        return (<div style={{ display: 'flex', gap: '.1rem' }}>
            <Alert onClick={() => {
                if (val.redirectUrl) {
                    markNotificationAsRead(val)
                    window.open(val.redirectUrl)

                }
            }} style={{ width: '500px', borderRadius: '15px', backgroundColor: getColor(val.notificationType, 0.8) }} severity={val.notificationType == "alert" ? "error" : val.notificationType} >
                <div style={{ display: 'flex', color: 'black', width: '100%' }}>
                    <div style={{ minWidth: '420px', maxWidth: '450px' }}>
                        <div style={{ fontSize: '.8rem', fontWeight: '600' }}>{val.notificationTitle}</div>
                         <div style={{ fontSize: '1.1rem' }}>{val.notificationBody}</div>
                                      </div>
                    {timeLeft > 0 ? <></> : <img src="/icon_new/close_v2.svg" width="20px" height="30px" style={{ marginRight: '10px' }} />}

                </div>
                <div style={{ width: '100%', fontSize: '1.1rem', display: 'flex', justifyContent: 'flex-end', color: 'black', padding: '.4rem 1rem' }}>{count > 1 ? <div>(+ {count - 1})</div> : <></>}</div>

            </Alert>



        </div>)
    }

    const LiveNotifications = () => {

        const [liveNotifications, setLiveNotifications] = useState({});
        const [showSnackbar, setShowSnackbar] = useState(false);
        const [snackbarBody, setSnackbarBody] = useState({});

        useEffect(() => {
            if (user.uid) {
                const firebaseRef = FirebaseApp.database().ref(`/users/${user.uid}/notifications/array`);

                let oldCount = null
                firebaseRef.on("value", async (snapshot) => {
                    if (snapshot.val()) {
                        const notificaitonsObject = snapshot.val()
                        setLiveNotifications(notificaitonsObject)
                    } else {
                        setLiveNotifications({})
                    }
                });
                return (() => {
                    firebaseRef.off()
                })
            }
        }, [])


        return (
            <div>

                {Object.keys(liveNotifications).length > 0 &&

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', position: 'absolute', top: '70px', right: '150px', background: 'white', zIndex: '10', padding: '10px', borderRadius: '25px' }} >
                        {[Object.keys(liveNotifications)[0]].map((kkey) => {
                            const val = liveNotifications[kkey];
                            return (
                                <AlertRow val={val} kkey={kkey} count={Object.keys(liveNotifications).length} />
                            )
                        })}
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }} >
                            {/* <Button color="secondary" >close</Button> */}

                        </div>

                    </div>
                }
            </div>
        )

    }

    return (
        <div style={{display:'flex', flexDirection:'row', position:'relative'}}>
            <NotificationHistory />
            <LiveNotifications />
        </div>
    )
}