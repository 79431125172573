
var isProduction = false;
if(process.env.REACT_APP_ENVIRONMENT === "production"){
    isProduction = true  
}

export const log = (msg) => {
    if(!isProduction){
        return console.log(msg)  
    } 
}

export const sheruLog = (msg, msg2) => {
    if(!isProduction){
        return console.log(msg, msg2)  
    } 
}