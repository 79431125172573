import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import  Box  from '@material-ui/core/Box';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  if(theme == PRIMARY_MAIN){
    // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("Navbar theme is : " + theme);
    return (
      <Box sx={{ width: 40, height: 40, ...sx }}>
        <div style={{ display:"flex" }}>
          <img
              src="/icon_new/sheru_black.svg"
              alt="sheru"
              style={{
                height: "100%",
                maxWidth:"75px",
                maxHeight:"75px",
                display: "inline-block",
              }}
            />
        </div>
      </Box>
    );
  }else{
    return (
      <Box sx={{ width: 40, height: 40, ...sx }}>
        <div style={{ display:"flex" }}>
          <img
              src="/icon_new/sheru_black.svg"
              alt="sheru"
              style={{
                height: "100%",
                maxHeight:"75px",
                maxWidth:"75px",
                display: "inline-block",
              }}
            />
        </div>
      </Box>
    );
  }
}
