import FirebaseApp from 'firebase/app';
import "firebase/auth";


export const getSessionToken = async() => {
    // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("getSessionToken");
    FirebaseApp.auth().onAuthStateChanged(function(user) {
      if (user) {
        // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("getSessionToken - user not null.");
        user.getIdToken().then(function(idToken) {
          // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("getSessionToken - user getting session token.");
          if(idToken != null){
            // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("getSessionToken - session token : " + typeof idToken);
            localStorage.setItem('sessionToken',idToken);
            return idToken;
          }else{
            // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("getSessionToken - session token is null.");
          }
        });
      }else{
        // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("getSessionToken - user is null.");
      }
    });
  }
