import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// hooks
// pages
import Login from "../pages/authentication/Login";
import Page500 from "../pages/Page500";

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const isAuthenticated =
    localStorage != null
      ? localStorage.getItem("userAuthenticated", false)
      : false;
  const isVerified =
    localStorage != null ? localStorage.getItem("userVerified", false) : false;

  // process.env.REACT_APP_ENVIRONMENT == "production"
  //   ? (() => {})()
  //   : console.log("AuthGuard : isAuthenticated : " + isAuthenticated);
  // process.env.REACT_APP_ENVIRONMENT == "production"
  //   ? (() => {})()
  //   : console.log("AuthGuard : isVerified : " + isVerified);

  // process.env.REACT_APP_ENVIRONMENT == "production"
  //   ? (() => {})()
  //   : console.log(
  //       "AuthGuard : isAuthenticated type : " + typeof isAuthenticated
  //     );
  // process.env.REACT_APP_ENVIRONMENT == "production"
  //   ? (() => {})()
  //   : console.log("AuthGuard : isVerified type : " + typeof isVerified);

  if (
    isAuthenticated !== null &&
    isAuthenticated.toString() == "true" &&
    isVerified !== null &&
    isVerified.toString() == "true"
  ) {
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("AuthGuard : going to somewhere");
    if (requestedLocation && pathname !== requestedLocation) {
      setRequestedLocation(null);
      return <Navigate to={requestedLocation} />;
    }
  } else if (
    isAuthenticated !== null &&
    isAuthenticated.toString() == "true" &&
    isVerified !== null &&
    isVerified.toString() == "false"
  ) {
    if (requestedLocation && pathname !== requestedLocation) {
      setRequestedLocation(null);
    }
    return <Page500 />;
  } else {
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("AuthGuard : going to login");
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }
  return <>{children}</>;
}
