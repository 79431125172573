import {gql} from '@apollo/client';
export const GET_NOTIFICATIONS = gql`
query EMSNotificationsMany($page: Int, $perPage: Int, $filter: FilterFindManyEMSNotificationsInput, $sort: SortFindManyEMSNotificationsInput) {
    EMSNotificationsPaginat(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      items {
        _id
        createdAt
        notificationBody
        notificationData
        notificationId
        markedAsRead
        notificationSubType
        notificationTitle
        notificationType
        seenAt
        redirectUrl
        acknowledgedUsers
        core
        node
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`