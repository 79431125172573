import PropTypes from "prop-types";
import AccountPopover from "./AccountPopover";
import NotificationComponent from "./NotificationComponent/NotificationComponent";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
// import MenuBar from "../../components/menuBar";
import Container from "@material-ui/core/Container";

import { useContext } from "react";
import { Context } from "src/contexts/UserContext";

DashboardNavbar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,

  isOpenSettingbar: PropTypes.bool,
  onOpenSettingbar: PropTypes.func,
  onCloseSettingbar: PropTypes.func,
};

export default function DashboardNavbar({
  isOpenSidebar,
  onOpenSidebar,
  onCloseSidebar,
  isOpenSettingbar,
  onOpenSettingbar,
  onCloseSettingbar,
}) {

  // console.log("DashboardNavbar - isOpenSidebar : " + isOpenSidebar);

  // console.log("DashboardNavbar - isOpenSettingbar : " + isOpenSettingbar);

  const theme = useTheme();
  const { userOb: userObject } = useContext(Context)

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: 'column', alignItems: 'center' }}>
      <Container maxWidth={"xl"}
        style={{
          paddingLeft: "0px",
          position: "fixed",
          zIndex: 2,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            zIndex: "3",
            opacity: "100%",
            justifyContent: "space-between",
            backgroundColor: `${theme.palette.background.pageBg}`,
            zIndex: 2,
            flexFlow: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "1.3rem",
              display: "flex",
              opacity: "100%",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {/* {userObject != null && userObject.sb != true && (<MenuBar
                isOpenSidebar={isOpenSidebar}
                onOpenSidebar={() => {
                  onOpenSidebar();
                }}
                onCloseSidebar={() => {
                  onCloseSidebar();
                }}
              />)} */}

            {
              userObject != null && userObject.sb == true && <div style={{ width: "1rem" }}></div>
            }

            <Link className="clickable" to={`/dashboard/app`} style={{ textDecoration: "none", background: "none" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "20px" }}>
                <img
                  src={theme.palette.mode === "light" ? "/logo/sheru/sheru_logo_black_v2.svg" : "/logo/sheru/sheru_logo_white_v2.svg"}
                  alt="sheru"
                  style={{
                    height: "22.5px",
                    marginRight: "10px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    display: "inline-block",
                  }}
                />

                <img
                  src={theme.palette.mode === "light" ?
                    "/logo/scalebat/scalebat_logo_blue_black.png" :
                    "/logo/scalebat/scalebat_logo_blue_white.png"}
                  alt="sheru"
                  style={{
                    height: "22.5px",
                    marginRight: "10px",
                    padding: '1px',
                    marginTop: "0px",
                    marginBottom: "0px",
                    display: "inline-block",
                  }}
                />

                {/* <div
                    style={{ display: "flex", flexDirection: "column", gap: "0px", 
                    textDecoration:"none", }}
                  >
                    <p
                      style={{
                        color: `${theme.palette.text.primary}`,
                        margin: "0px",
                        padding: "0px",
                        alignSelf: "center",
                        textDecoration:"none",
                        fontSize: "100%",
                      }}
                    >
                      ScaleBat
                    </p>
                  </div> */}
              </div>
            </Link>

          </div>

          <div
            // onClick={()=>{setIsOpen(!isOpen)}}
            style={{
              display: "flex",
              padding: "0.4rem 0.4rem",
              opacity: "100%",
              gap: '1rem',
              marginRight: "10px",
              borderRadius: "25px",
            }}
          >

            {userObject && <NotificationComponent
              user={userObject}
            />}


            <AccountPopover
              isOpenSettingbar={isOpenSettingbar}
              onOpenSettingbar={() => {
                onOpenSettingbar();
              }}
              onCloseSettingbar={() => {
                onCloseSettingbar();
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
