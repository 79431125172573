import { combineReducers } from 'redux';
import keplerGlReducer from 'kepler.gl/reducers';

import storage from 'redux-persist/lib/storage';
// slices




// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  keplerGl:keplerGlReducer,
 
});

export { rootPersistConfig, rootReducer };
