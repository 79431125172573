import {useNavigate} from 'react-router-dom';
import { experimentalStyled as styled } from '@material-ui/core/styles';
// material
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Page from 'src/components/Page';
import { SeverErrorIllustration } from '../assets';
import { LOCAL_STORAGE_USER_AUTHENTICATED, LOCAL_STORAGE_USER_VERIFIED } from "../constant/LoginConstants"
import firebase from "firebase/app";



// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------



export default function Page500() {

  const navigate = useNavigate();



  
const handleLogout = async () => {

  try {
    // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("handleLogout");
    
    localStorage.setItem(LOCAL_STORAGE_USER_AUTHENTICATED, false);
    localStorage.setItem(LOCAL_STORAGE_USER_VERIFIED, false);
    await firebase.auth().signOut();

    // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("handleLogout : auth : " + localStorage.getItem(LOCAL_STORAGE_USER_AUTHENTICATED, true));
    // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("handleLogout : verify : " + localStorage.getItem(LOCAL_STORAGE_USER_VERIFIED, true));

    // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("logout done");

    navigate("/auth/login");
  
  } catch (error) {
    // console.error(error);
    alert('Unable to logout');
  }
};


  return (
    <RootStyle title="500">
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Not Authorised!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>You dont have the required authorization to view this page </Typography>

          <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

          <Button onClick={handleLogout} size="large" variant="contained">
            Log out
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
