import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
//
import PropTypes from "prop-types";
import Scrollbar from 'src/components/Scrollbar';
import { MIconButton } from '../@material-extend';
import SettingMode from './SettingMode';
import SettingColor from './SettingColor';
import SettingDirection from './SettingDirection';
import SettingFullscreen from './SettingFullscreen';
import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import Stack from "@material-ui/core/Stack";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;

Settings.propTypes = {
  isOpenSettingbar: PropTypes.bool,
  onOpenSettingbar: PropTypes.func,
  onCloseSettingbar: PropTypes.func,
};

export default function Settings({isOpenSettingbar, onOpenSettingbar, onCloseSettingbar}) {

  // console.log("Settings : " + "isOpenSettingbar - " + isOpenSettingbar);

  const theme = useTheme();

  useEffect(() => {
  }, [isOpenSettingbar]);

  const handleClose = () => {
    onCloseSettingbar();
  };

  return (
    <div>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpenSettingbar} onClick={handleClose} />

      <Box
        sx={{
          top: 12,
          bottom: 12,
          right: 0,
          position: 'fixed',
          zIndex: (theme) => theme.zIndex.drawer + 2,
          ...(isOpenSettingbar && { right: 12 })
        }}
      >
        <Paper
          sx={{
            height: 1,
            width: '0px',
            overflow: 'hidden',
            boxShadow: (theme) => theme.customShadows.z24,
            transition: (theme) => theme.transitions.create('width'),
            ...(isOpenSettingbar && { width: DRAWER_WIDTH })
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
            <Typography variant="subtitle1">Settings</Typography>
            <MIconButton onClick={handleClose}>
              <Icon icon={closeFill} width={20} height={20} />
            </MIconButton>
          </Stack>
          <Divider />

          <Scrollbar sx={{ height: 1 }}>
            <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 15 }}>
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">Mode</Typography>
                <SettingMode />
              </Stack>

              <Stack spacing={1.5}>
                <Typography variant="subtitle2">Direction</Typography>
                <SettingDirection />
              </Stack>

              <Stack spacing={1.5}>
                <Typography variant="subtitle2">Color</Typography>
                <SettingColor />
              </Stack>

              <SettingFullscreen />
            </Stack>
          </Scrollbar>
        </Paper>
      </Box>
    </div>
  );
}
