const ApiConstants = {
    // REACT_APP_FRONTEND_URL : "https://sheru-website-staging.web.app/",
    REACT_APP_FRONTEND_URL : "https://sheru.se/",
    // REACT_APP_FRONTEND_URL : "http://localhost:3000/",

    REACT_APP_POSTEND_URL : ".html",
    // REACT_APP_POSTEND_URL : "",

    // HOME_LINK : "https://sheru-website-staging.web.app/"
    HOME_LINK : "https://sheru.se/"
    // HOME_LINK : "/"
}

export default ApiConstants;