import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import DashboardNavbar from './DashboardNavbar';
// import DashboardSidebar from './DashboardSidebar';
import PageFooter from 'src/components/footer/PageFooter'

import AccountPopover from "src/layouts/dashboard/AccountPopover"

import Settings from 'src/components/settings';

// import MenuBar from "src/components/menuBar";

// pages
import Login from '../../pages/authentication/Login';

const ecoDemoMode = localStorage.getItem("ecoDemoMode");

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  transition:'0.4s ease-in',
  background:`${theme.palette.background.default}`,
}));

const MainStyle = styled('div')(({ theme }) => ({
  // flexGrow: 1,
  overflow: 'auto',
  width:'100%',
  paddingTop:"100px",
  minHeight: ecoDemoMode ? '80vh' : '100vh',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function DashboardLayout() {
  

  const theme = useTheme();
  
  const [openSideBar, setOpenSideBar] = useState(false);
  const [openSettingBar, setOpenSettingBar] = useState(false);

  var isAuthenticated = localStorage != null ? localStorage.getItem('userAuthenticated', false) : false;
  var isVerified = localStorage != null ? localStorage.getItem('userVerified', false) : false;

  const onOpenSidebar = () => {
    setOpenSideBar(true);
  }
  
  const onCloseSidebar = () => {
    setOpenSideBar(false);
  }

  const onOpenSettingbar = () => {
    setOpenSettingBar(true);
  }
  const onCloseSettingbar = () => {
    setOpenSettingBar(false);
  }

  if (isAuthenticated.toString() == "true"){
    if(isVerified.toString() == "true"){
 
      // console.log("DashboardLayout - " + "openSideBar : " + openSideBar);
      // console.log("DashboardLayout - " + "openSettingBar : " + openSettingBar);
      
      return (
        <RootStyle>
       
          {/* {ecoDemoMode == "1" &&
            <div style={{display:'flex', flexDirection:"row", padding:'10px', position:'fixed', zIndex:'5', left:'0', top:'0'}}>
              <MenuBar
                isOpenSidebar={openSideBar}
                onOpenSidebar={() => {
                  onOpenSidebar();
                }}
                onCloseSidebar={() => {
                  onCloseSidebar();
                }}
              />
             
            </div>
          } */}

            {/* <DashboardSidebar isOpenSidebar={openSideBar} onOpenSidebar={onOpenSidebar} onCloseSidebar={onCloseSidebar} 
                isOpenSettingbar={openSettingBar} onOpenSettingbar={onOpenSettingbar} onCloseSettingbar={onCloseSettingbar} 
            /> */}

          <div style={{display:'flex', flexDirection:"column", width:"100%", minHeight:"100vh", alignItems:"center"}}>
          
            {ecoDemoMode == "1" ? 
              <div style={{display:'flex', flexDirection:"row", padding:'20px 10px', position:'fixed', zIndex:'5', right:'0', top:'0'}}>
                <AccountPopover/>
              </div> : 
              <DashboardNavbar isOpenSidebar={openSideBar} onOpenSidebar={onOpenSidebar} onCloseSidebar={onCloseSidebar}
                isOpenSettingbar={openSettingBar} onOpenSettingbar={onOpenSettingbar} onCloseSettingbar={onCloseSettingbar} />
            }
              <MainStyle> 
                <Outlet />
              </MainStyle>
              <PageFooter style={{ backgroundColor:`${theme.palette.background.page}` }}/>
          </div>

          <Settings isOpenSettingbar={openSettingBar} onOpenSettingbar={onOpenSettingbar} onCloseSettingbar={onCloseSettingbar}/>

        </RootStyle>
      );
    }else{
      // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("DashboardLayout : showing login");
      return <Login />;
    }
  }else{
    // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("DashboardLayout : showing login");
    return <Login />;
  }
}
